/**
 * 接口
 */
import { get, post } from "./axios";

// 登录
export const login = (params) => post("/user/login", params);
// 登出
export const logout = (params) => get("/user/logout", params);

// 获取用户列表
export const getUserList = (params) => get("/user/list", params);
// 删除用户
export const deleteUserById = (params) => get("/user/delete", params);
// 获取用户信息
export const getUserInfo = (params) => get("/user/info", params);
// 修改用户
export const updateUser = (params) => post("/user/update", params);
// 审核司机资料
export const examineUser = (params) => get("/user/examine", params);

// 获取代理商列表
export const getCompanyList = (params) => get("/company/list", params);
// 获取代理商信息
export const getCompanyInfo = (params) => get("/company/info", params);
// 添加代理商
export const saveCompany = (params) => post("/company/save", params);
// 修改代理商
export const updateCompany = (params) => post("/company/update", params);
// 删除代理商
export const deleteCompanyById = (params) => get("/company/delete", params);

// 获取商家列表
export const getBusinessList = (params) => get("/business/list", params);
// 删除商家
export const deleteBusinessById = (params) => get("/business/delete", params);
// 获取代理商信息
export const getBusinessInfo = (params) => get("/business/info", params);
// 修改代理商
export const updateBusiness = (params) => post("/business/update", params);
// 添加代理商
export const saveBusiness = (params) => post("/business/save", params);

// 删除商品
export const deleteProductById = (params) => get("/product/delete", params);
// 获取商家列表
export const getProductList = (params) => get("/product/list", params);
// 获取商品信息
export const getProductInfo = (params) => get("/product/info", params);
// 修改商品
export const updateProduct = (params) => post("/product/update", params);
// 添加商品
export const saveProduct = (params) => post("/product/save", params);

// 获取订单列表
export const getOrderList = (params) => get("/order/list", params);
// 删除订单
export const deleteOrderById = (params) => get("/order/delete", params);
// 修改订单司机
export const updateOrderDriver = (params) =>
  post("/order/updateOrderDriver", params);

// 获取流水列表
export const getPropertywaterList = (params) =>
  get("/propertywater/list", params);

// 获取推广记录列表
export const getPopularizeList = (params) => get("/popularize/list", params);

// 获取意向记录列表
export const getIntentionList = (params) => get("/intention/list", params);
// 删除意向记录
export const deleteIntentionById = (params) => get("/intention/delete", params);

// 删除菜单
export const deleteMenuById = (params) => get("/menu/delete", params);
// 获取菜单列表
export const getMenuList = (params) => get("/menu/list", params);
// 获取菜单信息
export const getMenuInfo = (params) => get("/menu/info", params);
// 添加菜单
export const saveMenu = (params) => post("/menu/save", params);
// 修改菜单
export const updateMenu = (params) => post("/menu/update", params);

// 删除角色
export const deleteRoleById = (params) => get("/role/delete", params);
// 获取角色列表
export const getRoleList = (params) => get("/role/list", params);
// 获取角色信息
export const getRoleInfo = (params) => get("/role/info", params);
// 添加角色
export const saveRole = (params) => post("/role/save", params);
// 修改角色
export const updateRole = (params) => post("/role/update", params);

// 删除发票
export const deleteTaxById = (params) => get("/tax/delete", params);
// 获取发票列表
export const getTaxList = (params) => get("/tax/list", params);
// 获取发票信息
export const getTaxInfo = (params) => get("/tax/info", params);
// 添加发票
export const saveTax = (params) => post("/tax/save", params);
// 修改发票
export const updateTax = (params) => post("/tax/update", params);

// 删除车辆
export const deleteVehicleById = (params) => get("/vehicle/delete", params);
// 获取车辆列表
export const getVehicleList = (params) => get("/vehicle/list", params);
// 获取车辆信息
export const getVehicleInfo = (params) => get("/vehicle/info", params);
// 添加车辆
export const saveVehicle = (params) => post("/vehicle/save", params);
// 修改车辆
export const updateVehicle = (params) => post("/vehicle/update", params);

// 修改积分
export const updateProperty = (params) => post("/property/update", params);
// 修改系统设置
export const updateSystem = (params) => post("/system/update", params);
// 获取系统设置
export const getSystem = (params) => get("/system/info", params);
// 获取首页数据
export const getIndexInfo = (params) => get("/company/indexInfo", params);
// 处理退款
export const refund = (params) => get("/order/refund", params);
