/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from "axios";
import { Message } from "element-ui";
import router from "../router";

var instance = axios.create({ timeout: 1000 * 15 });

// 环境的切换
// instance.defaults.baseURL = "https://405v96917l.eicp.vip/ca1r";
instance.defaults.baseURL = "https://www.jsfgx.online/newcar/newcar";
// instance.defaults.baseURL = "http://127.0.0.1/ca1r";

// post请求头
instance.defaults.headers.post["Content-Type"] =
  "application/json;charset=UTF-8";

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    if (localStorage.getItem("userInfo")) {
      const token = JSON.parse(localStorage.getItem("userInfo")).token;
      token && (config.headers.Token = token);
    }

    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.code === 4001) {
        Message.error("没有登录，请登录");
        router.push("login");
      } else if (response.data.code === 4002) {
        Message.error("没有权限");
      } else if (response.data.code === 4003) {
        Message.error(response.data.msg);
      }
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    if (error.response.status) {
      Message.error("网络异常，请稍后重试或联系管理员");
      return Promise.reject(error.response);
    }
  }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
