<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="80px"
    >
      <el-form-item label="菜单名" prop="name">
        <el-input v-model="dataForm.name" placeholder="菜单名"></el-input>
      </el-form-item>
      <el-form-item label="url" prop="url">
        <el-input v-model="dataForm.url" placeholder="url"></el-input>
      </el-form-item>
      <el-form-item label="图标" prop="icon">
        <el-input v-model="dataForm.icon" placeholder="图标"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input v-model="dataForm.sort" placeholder="排序"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getMenuInfo, updateMenu, saveMenu } from "../../api/api";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: 0,
        parentId: "",
        name: "",
        url: "",
        sort: 0,
        icon: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
      },
      dataRule: {
        name: [{ required: true, message: "菜单名不能为空", trigger: "blur" }],
        url: [{ required: true, message: "url不能为空", trigger: "blur" }],
        sort: [{ required: true, message: "排序不能为空", trigger: "blur" }],
        icon: [{ required: true, message: "图标不能为空", trigger: "blur" }],
      },
    };
  },
  methods: {
    init(id) {
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          getMenuInfo({ id: this.dataForm.id }).then((data) => {
            if (data && data.code === 0) {
              this.dataForm = data.data;
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;

          if (this.dataForm.id) {
            // 修改
            updateMenu(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            saveMenu(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>
